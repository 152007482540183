<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <h5 class="font-weight-bold mb-0 text-danger">
          <span class="text-uppercase">SİLİNEN MARKALAR</span>
        </h5>
      </div>
      <div class="card-body position-relative" style="min-height: 200px">
        <WeTable
          v-bind:ajax="true"
          v-bind:index="false"
          v-bind:allowSortIndex="false"
          v-bind:loading="loading"
          v-bind:fetchOnMount="true"
          v-bind:data="brand.list"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-bind:table-result="tableResult"
          v-on:on-action="onAction"
          v-on:filter-language="filterLanguage"
          v-on:make-request="makeRequest"
        ></WeTable>
      </div>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Trash",
  data() {
    return {
      loading: false,
      languageFilter: "tr",
      tableActions: [
        {
          icon: "fas fa-sync fa-sm",
          class: "btn-outline-primary",
          action: "restore",
          tooltip: "Geri Al",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
          tooltip: "Kalıcı Olarak Sil",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "name", th: "Marka Adı", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean_disabled" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("brand", ["getList", "restore", "remove"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onRemove(data.row, data.index);
      } else if (data.key == "restore") {
        this.onRestore(data.row, data.index);
      }
    },
    onRestore(row, index) {
      let message = `${row.name} adlı markayı geri almak istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Geri Al",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("r");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.restore({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Marka Başarıyla Geri Alındı");
                  this.brand.list.splice(index, 1);
                } else {
                  this.$swal({
                    title: "Marka Geri Alınamadı",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onRemove(row, index) {
      let message = `${row.name} markasını kalıcı olarak silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        text: "Bu işlem geri alınamaz.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("f");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.remove({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Marka Başarıyla Silindi");
                  this.brand.list.splice(index, 1);
                } else {
                  this.$swal({
                    title: "Marka Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    filterLanguage(lang) {
      this.languageFilter = lang;
      this.makeRequest();
    },
    makeRequest(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;
      this.getList({
        lang: this.languageFilter,
        table: requestData,
        deleted: true,
        onSuccess: (result) => {
          helper.copy(result.data, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    checkPermission(code) {
      return permission.check("brand", code);
    },
  },
  computed: {
    ...mapState(["brand", "shared", "session"]),
  },
  mounted() {
    let locales = this.session?.config["site.available_locales"];
    if (locales != undefined) {
      locales = JSON.parse(locales);
      if (locales.length > 1)
        this.columns.push({ name: "locales", th: "Dil", type: "language" });
    }
  },
};
</script>
